<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="发放记录"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <div class="mainWrap">
        <div class="query_box">
          <div class="query_box_item">
            <span class="query_box_item_tit">优惠券类型</span>
            <div class="query_box_item_right">
              <div class="section">
                <span class="selPlateNum" @click="openTypePopup">{{
                  selTypeVal ? selTypeVal : '选择优惠券类型'
                }}</span>
                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
                <van-popup
                  v-model="showTypePopup"
                  @close="onTypeClose"
                  position="bottom"
                >
                  <van-picker
                    :columns="couponTypeColumns"
                    @change="onTypeChange"
                    @confirm="onTypeConfirm"
                    @cancel="onTypeCancel"
                    show-toolbar
                    title="选择优惠券类型"
                    visible-item-count="5"
                    default-index="0"
                  />
                </van-popup>
              </div>
            </div>
          </div>
          <div class="query_box_item">
            <span class="query_box_item_tit">优惠券状态</span>
            <div class="query_box_item_right">
              <div class="section">
                <span class="selPlateNum" @click="openStatusPopup">{{
                  selStatusVal ? selStatusVal : '选择优惠券状态'
                }}</span>
                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
                <van-popup
                  v-model="showStatusPopup"
                  @close="onStatusClose"
                  position="bottom"
                >
                  <van-picker
                    :columns="couponStatusColumns"
                    @change="onStatusChange"
                    @confirm="onStatusConfirm"
                    @cancel="onStatusCancel"
                    show-toolbar
                    title="选择优惠券状态"
                    visible-item-count="5"
                    default-index="0"
                  />
                </van-popup>
              </div>
            </div>
          </div>

          <div class="query_box_item">
            <span class="query_box_item_tit">时间范围</span>
            <div class="query_box_item_right" @click="openDate">
              <div class="section">
                <span class="showCommon">
                  {{ currentDateStartFormat }} 至 {{ currentDateEndFormat }}</span
                >

                <img
                  src="../../../assets/img/rightjt@2x.png"
                  class="rightIcon"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="list_box">
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            success-text="刷新成功"
            :disabled="errorFlag"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="没有更多了"
              :error.sync="error"
              error-text="请求失败，点击重新加载"
              @load="onLoad"
            >
              <div
                class="group_recharge"
                v-for="item in giveRecordList"
                :key="item.id"
                @click="openDetail(item)"
              >
                <div class="itemBox">
                  <div class="text-wrapper_6">
                    <div class="plateNum">
                      <img
                        src="../../../assets/img/fapiaojilu1@2x.png"
                        class="icon_3"
                        mode=""
                      />
                      <span class="text_12">{{ item.plateNum }}</span>
                    </div>
                    <span class="text_13 couponStatus">{{
                      couponStatusNameFormatter(item.status)
                    }}</span>
                  </div>
                  <div class="lineline"></div>
                  <!-- <van-divider/> -->

                  <div class="text-wrapper_6">
                    <span class="text_12">ID</span>
                    <span class="text_13">{{ item.ID }}</span>
                  </div>
                  <div class="text-wrapper_6">
                    <span class="text_12">领取时间</span>
                    <span class="text_13">{{ formatDate(item.in_time) }}</span>
                  </div>
                  <div class="text-wrapper_6">
                    <span class="text_12">使用时间</span>
                    <span class="text_13">{{ formatDate(item.out_time) }}</span>
                  </div>
                  <div class="text-wrapper_6">
                    <span class="text_12">类型</span>
                    <span class="text_13">{{
                      couponNameFormatter(item.type)
                    }}</span>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
          <!-- 空状态 -->
          <van-empty
            v-if="giveRecordList.length == 0"
            description="暂无匹配数据"
          />
          <van-empty
            v-if="giveRecordList.length !== 0 && isError"
            image="error"
            description="查询异常"
          />
        </div>
      </div>
      <!-- 时间选择器 -->
      <date-select
        :show-date="showDatePopup"
        @get-on-close="getOnCloseDate"
        @doneHaveSelDate="doneHaveSelDate"
      ></date-select>
    </div>
  </div>
</template>
<script>
import DateSelect from '../../../components/DateSelect.vue'
// import Pagination from '../../../components/Pagination.vue'
import * as common from '../../../api/common'
import * as api from '../../../api/api'
import * as util from '../../../api/util'
import { Toast } from 'vant'
// import moment from 'moment'
export default {
  // components: { DateSelect, Pagination },
  components: { DateSelect },
  data () {
    return {
      tokenInfoObj: {},
      active: 0,

      show: false,

      objectArray: [{
        id: 0,
        money: '22',
        groupName: '银湖分组',
        date: '2022/12/12 12:12:12',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      },
      {
        id: 1,
        money: '25',
        groupName: '银湖分组2',
        date: '2023-11-11 13:13:13',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      },
      {
        id: 2,
        money: '25',
        groupName: '银湖分组2',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      }
      ],
      objectArray2: [{
        id: 0,
        money: '22',
        groupName: '银湖缴费分组',
        date: '2022-12-12 12:12',
        src: '../../../assets/img/rightjt.png'
      },
      {
        id: 1,
        money: '25',
        groupName: '银湖缴费分组',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png'
      },
      {
        id: 2,
        money: '25',
        groupName: '银湖缴费分组',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png'
      }
      ],
      vhColumns: ['全部', '浙A12345', '中国', '巴西', '日本'],
      couponTypeColumns: ['全部', '金额减免券', '时长减免券', '折扣减免券', '全免券'],
      couponStatusColumns: ['全部', '未使用', '已使用', '已回收', '已过期'],
      index: 0,
      array2: ['今天', '明天', '前天', '巴西', '日本'],
      index2: 0,
      recentDates: '',
      lastAmonth: '',
      lastThreeMonth: '',
      plateNum: '',
      user_info: {},
      token: '',
      giveRecordList: [
        {
          id: 0,
          money: '22',
          groupName: '银湖分组',
          type: 1,
          status: 1,
          in_time: '2022-12-12 12:12:12',
          out_time: '2022-12-12 12:12:12',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 1,
          money: '22',
          groupName: '银湖分组',
          type: 2,
          status: 2,
          in_time: '2022-12-12 12:12:12',
          out_time: '2022-12-12 12:12:12',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 2,
          money: '22',
          groupName: '银湖分组',
          type: 3,
          status: 3,
          in_time: '2022-12-12 12:12:12',
          out_time: '2022-12-12 12:12:12',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 3,
          money: '22',
          groupName: '银湖分组',
          type: 4,
          status: 4,
          in_time: '2022-12-12 12:12:12',
          out_time: '2022-12-12 12:12:12',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        }

      ],
      monthPayRecordList: [
        {
          id: 0,
          money: '22',
          groupName: '银湖分组',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        }
      ],
      isError: false,
      showVhPopup: false,
      selVhVal: '',
      showDatePopup: false,
      currentDateStartFormat: '',
      currentDateEndFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      pageOption: {
        page_no: 1,
        page_size: 10,
        total: 0
      },
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      errorFlag: false,
      loadingMonth: false,
      finishedMonth: false,
      refreshingMonth: false,
      errorMonth: false,
      errorFlagMonth: false,

      showTypePopup: false,
      showStatusPopup: false,
      selTypeVal: '',
      selStatusVal: ''

    }
  },
  mounted () {
    this.currentDateStartFormat = util.getDateOneMonthBeforeToday(this.currentDateEndFormat)
    this.queryTab()
  },
  computed: {

  },
  methods: {

    // 优惠券名称格式化
    couponNameFormatter (type) {
      return util.getCouponName(type)
    },
    couponStatusNameFormatter (type) {
      return util.getCouponStatusName(type)
    },
    openTypePopup () {
      this.showTypePopup = true
    },

    onTypeCancel () {
      this.showTypePopup = false
    },
    onTypeConfirm (value) {
      this.selTypeVal = value
      this.showTypePopup = false
      this.queryRecord()
    },
    onTypeChange () {

    },
    onTypeClose () {

    },
    openStatusPopup () {
      this.showStatusPopup = true
    },

    onStatusCancel () {
      this.showStatusPopup = false
    },
    onStatusConfirm (value) {
      this.selStatusVal = value
      this.showStatusPopup = false
      this.queryRecord()
    },
    onStatusChange () {

    },
    onStatusClose () {

    },
    formattedDate (dateString) {
      return dateString.replace(/-/g, '/') //
    },
    formatDate (date) {
      return common.fmtDate(date, 'yyyy/MM/dd hh:mm:ss')
    },
    handlePageChange (page) {
      console.log('New page number:', page)
      this.queryTab()
    },
    doneHaveSelDate (date1, date2) {
      this.currentDateStartFormat = date1
      this.currentDateEndFormat = date2
      this.showDatePopup = false
      this.queryRecord()
    },
    getOnCloseDate () {
      this.showDatePopup = false
    },

    openDate () {
      this.showDatePopup = true
    },
    queryTab () {
      if (this.active === 0) {
        this.queryRecord()
      } else {
        this.queryMonthPayRecord()
      }
    },
    // 切换Tab
    onChange () {
      console.log(this.active)
      this.pageOption.page_no = 1
      this.queryTab()
    },
    openDetail (item) {
      const encodedItem = encodeURIComponent(JSON.stringify(item))

      this.$router.push({ name: 'giveRecordDetail', params: { item: encodedItem } })
      // this.$router.push({ path: '/myBillDetail', query: { type: type, data: item } })
    },
    // 查询停车缴费
    queryRecord () {
      // todo 参数完善
      // todo 微信授权之后应该换取token保存相应的userinfo信息
      const para = {
        customer_id: 148,
        page_no: this.pageOption.page_no,
        page_size: this.pageOption.page_size,
        begin_time: this.currentDateStartFormat === '' ? '' : this.currentDateStartFormat + ' 00:00:00',
        end_time: this.currentDateEndFormat === '' ? '' : this.currentDateEndFormat + ' 23:59:59',
        vh_p_number: this.selVhVal === '全部' ? '' : this.selVhVal
      }
      api
        .axiosRequest(api.urlList.parkRecord.park, para)
        .then((res) => {
          Toast.clear()
          if (res && res.code === common.resCode.OK) {

          } else {
            this.pageOption.total = 100
          }
        })
        .catch((e) => {
          console.log('error log:', e)
        })
    },
    // 月卡续费
    queryMonthPayRecord () {
      console.log('查询月卡缴费')
      this.pageOption.total = 24
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    // 停车
    onRefresh () {
      this.giveRecordList = []
      this.finished = false
      this.loading = true
      this.onLoad()
    },
    onLoad () {
      // 监听到即执行
      console.log('滑到底了')

      //   再次调用 查询 下一页数据
      if (this.refreshing) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.queryRecord()
      } else {
        if (this.errorFlag) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlag = false
          this.queryRecord()
          return false
        }
        // 触底加载
        if (this.giveRecordList.length >= this.pageOption.total) {
          this.finished = true
        } else {
          this.pageOption.page_no = this.pageOption.page_no + 1
          this.queryRecord()
        }
      }
    },
    // 月卡
    onRefreshMonth () {
      this.monthPayRecordList = []
      this.finishedMonth = false
      this.loadingMonth = true
      this.onLoadMonth()
    },
    onLoadMonth () {
      // 监听到即执行
      console.log('滑到底了')

      //   再次调用 查询 下一页数据
      if (this.refreshingMonth) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.queryMonthPayRecord()
      } else {
        if (this.errorFlagMonth) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlagMonth = false
          this.queryMonthPayRecord()
          return false
        }
        // 触底加载
        if (this.monthPayRecordList.length >= this.pageOption.total) {
          this.finishedMonth = true
        } else {
          this.pageOption.page_no = this.pageOption.page_no + 1
          this.queryMonthPayRecord()
        }
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  padding: 0.16rem 0;
  // overflow: hidden;
  .rightIcon {
    width: 0.08rem;
    height: 0.14rem;
    margin: 0.03rem 0 0.03rem 0.05rem;
  }
  .lineline {
    height: 0.01rem;
    background-color: #e2e2e2;
    margin-top: 0.07rem;
    margin-bottom: 0.07rem;
  }
  .query_box {
    width: 3.43rem;
    // height: 5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.12rem;
    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      /* width: 686rpx; */
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .selPlateNum {
            font-size: 0.14rem;
          }
        }
      }
    }

    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .showCommon {
            font-size: 0.14rem;
          }
          .rightIcon {
            width: 0.08rem;
            height: 0.14rem;
            margin: 0.03rem 0 0.03rem 0.05rem;
          }
        }
        .image_4-1 {
          position: absolute;
          left: 0;
          top: -0.01rem;
          width: 3.42rem;
          height: 0.01rem;
          background-color: #f0f0f0;
        }
      }
    }
  }

  .list_box {
    background-color: #F8F7FC;
    width: 3.43rem;
    align-self: center;
    // margin-top: 0.16rem;
    display: flex;
    flex-direction: column;
    // padding: 0.08rem 0.01rem 0.01rem 0;
    .group_recharge{
      background-color: #fff;
      margin-bottom: 0.16rem;
    }
    // overflow-y: auto;
    .itemBox {
      width: 3.25rem;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      margin: 0.04rem 0.07rem 0 0.1rem;
      .text-wrapper_6 {
        // width: 3.23rem;
        margin-top: 0.16rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        padding: 0 0.08rem;
        .plateNum {
          width: 1rem;
          display: flex;
          justify-content: space-between;
          .icon_3 {
            width: 0.2rem;
            height: 0.16rem;
            margin: 0.03rem 0 0 0;
          }
          .text_12 {
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 0.14rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.2rem;
          }
        }
        .text_12 {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
        .text_13 {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
        .couponStatus {
          overflow-wrap: break-word;
          color: #38d500;
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }
      .text-wrapper_6:last-child{
        margin-bottom: 0.16rem;
      }
      .mainBox {
        width: 3.25rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        /* margin: 8rpx 14rpx 0 20rpx; */
        .mainBoxLeft {
          /* width: 280rpx; */
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          .mainBoxLeftIcon {
            width: 0.2rem;
            height: 0.16rem;
            margin: 0.03rem 0 0.43rem 0;
          }
          .mainBoxLeftCenter {
            display: flex;
            flex-direction: column;
            margin-left: 0.06rem;
            .mainBoxLeftCenterNum {
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 0.16rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.22rem;
              margin-right: 0.39rem;
            }
            .mainBoxLeftCenterType {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin: 0.04rem 0.55rem 0 0;
            }

            .mainBoxLeftCenterDate {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin-top: 0.02rem;
            }
          }
        }
        .mainBoxRight {
          margin-bottom: 0.19rem;
          display: flex;
          flex-direction: column;
          .mainBoxRightMoney {
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 0.16rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.22rem;
            margin-left: 0.17rem;
          }
        }
      }
    }
    .info {
      .text-wrapper_12 {
        width: 3.22rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.15rem 0.18rem 0 0.1rem;
        .text_7 {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }

        .text_8 {
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }
    }
  }
}
</style>
